
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import UpdateEvent from "@/models";
import RoleEditModal from "@/modals/user/RoleEditModal.vue";
import { Role, UserModel } from "@/models/user/user.model";
import HelpUserRoleModal from "@/modals/user/HelpUserRoleModal.vue";
import UserService from "@/services/user/user.service";
import UserMgmtService from "@/services/user/user-mgmt.service";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import { cloneDeep } from "lodash";
import UserApprovalEditModal from "@/modals/user/ApprovalEditModal.vue";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPage, MixinsAppBarMenu).extend({
  name: "MgmtUserView",
  components: { UserApprovalEditModal, HelpUserRoleModal, RoleEditModal },
  data: () => ({
    dateFormatPattern: "YYYY년 MM월 DD일",
    user: null as UserModel | null,
    visible: {
      edit: false,
    },
    view: {
      phone: "",
      createdAt: "",
    },
    form: {
      role: "",
    },
    modal: {
      helpUserRole: {
        visible: false,
      },
      approval: {
        visible: false,
        params: {
          approvalType: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
      roleEdit: {
        visible: false,
        params: {
          roles: null as null | Role[],
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params;
      //console.log("query : ", query);
      if (this.isBlank(params.id)) {
        await this.notFound();
        return;
      }
      const id = Number(params.id);
      try {
        const myUser = (await this.$store.getters["auth/user"]()) as UserModel;
        this.visible.edit = myUser.id !== id;

        const viewUser = (await UserMgmtService.get(id)) as UserModel;

        // console.log("user : ", user);
        this.user = viewUser;

        if (this.visible.edit) {
          if (myUser.roles.indexOf(Role.MANAGER) > -1 && viewUser.roles.indexOf(Role.ADMIN) > -1) {
            this.visible.edit = false;
          }
        }

        // test
        // user.email = "khwan7@gmail.com";
        // user.company.role = "MANAGER";

        this.view.phone = core.utils.format.hyphenPhone(viewUser.phone);
        this.view.createdAt = this.dateFormat(viewUser.createdAt);

        this.appBarChangeMenuVisible("delete", this.isAdminRole);
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }
    });
  },
  computed: {
    isAdminRole() {
      return this.$store.getters["auth/isAdminRole"];
    },
    isManagerRoleHigher() {
      return this.$store.getters["auth/isManagerRoleHigher"];
    },
  },
  watch: {
    "modal.roleEdit.updateEvent"(event) {
      //console.log("event : ", event);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const role = event.item as string;
          this.changeRole(role as Role);
        }
      }
    },
    "modal.approval.updateEvent"(event) {
      //console.log("event : ", event);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const role = event.item as string;
          if (role === "ALLOW") {
            this.changeRole(Role.USER, true);
          }
        }
      }
    },
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "delete") {
        this.deleteUser();
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    async deleteUser() {
      if (this.user != null) {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: "정말 삭제하시겠습니까?",
          showCancelButton: true,
          cancelButtonText: "취소",
          confirmButtonText: "삭제",
        });
        if (confirmResult === "confirm") {
          await UserMgmtService.delete(this.user.id);
          await core.alert.show({
            title: "확인",
            body: "회원 정보가 삭제되었습니다",
          });
          this.goBack(UpdateEventType.DELETE, this.user);
        }
      }
    },
    getMailLink(email) {
      return core.mobile.getMailLink(email);
    },
    getTelLink(phone) {
      return core.mobile.getTelLink(phone);
    },
    showHelpUserRole() {
      const modal = this.modal.helpUserRole;
      modal.visible = true;
    },
    rolesToStyle() {
      if (this.user != null) {
        const roles = this.user.roles;
        if (roles.indexOf(Role.WAIT_USER) > -1) {
          return "background-color: rgba(235, 116, 47, 0.2); color: #eb742f; caret-color: #eb742f;";
        } else if (roles.indexOf(Role.USER) > -1) {
          return "background-color: rgba(60, 212, 160, 0.2); color: #3cd4a0; caret-color: #3cd4a0;";
        } else if (roles.indexOf(Role.MANAGER) > -1) {
          return "background-color: rgba(83, 103, 254, 0.2); color: #536dfe; caret-color: #536dfe;";
        } else if (roles.indexOf(Role.ADMIN) > -1) {
          return "background-color: rgba(83, 103, 254, 0.2); color: #536dfe; caret-color: #536dfe;";
        }
      }
      return "";
    },
    rolesToText() {
      if (this.user != null) {
        return UserService.rolesToText(this.user.roles);
      }
      return "알 수 없음";
    },

    async changeRole(role: Role, ignoreAlert?: boolean) {
      console.log("change role : ", role);
      if (this.user == null) return;
      if (this.user.roles.indexOf(role) > -1) {
        return;
      }

      let result = "confirm";
      if (!ignoreAlert) {
        const textRole = UserService.roleToText(role);
        // console.log("textRole : ", textRole);
        result = (await core.alert.show({
          title: "확인",
          body: `사용자 역할을 "${textRole}"로 변경하시겠습니까?`,
          showCancelButton: true,
          confirmButtonText: "계속",
        })) as string;
      }

      if (result === "confirm") {
        core.loader.show();
        try {
          this.user = await UserMgmtService.updateRole(this.user.id, role);
          this.updatePreVmEvent(UpdateEventType.UPDATE, cloneDeep(this.user));
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    showApprovalModal() {
      // approval
      const modal = this.modal.approval;
      modal.params.approvalType = "DENY";
      modal.visible = true;
    },
    showRoleEditModal() {
      const user = this.user;
      if (user != null) {
        if (user.roles.indexOf(Role.WAIT_USER) > -1) {
          this.showApprovalModal();
        } else {
          const modal = this.modal.roleEdit;
          if (user.roles) {
            modal.params.roles = user.roles;
            modal.visible = true;
          } else {
            console.log("invalid user : ", user);
          }
        }
      }
    },
  },
});
