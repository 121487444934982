import AbstractTableService from "@/services/core/abstract-table.service";
import { ConferenceModel, ConferenceRoomRequestModel } from "@/models/conference/conference.model";
import core from "@/core";

class ConferenceService extends AbstractTableService<ConferenceModel> {
  constructor() {
    super("/api/v1/conference", "conference");
  }

  uploadImage(formData: FormData) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`${this.url}/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getRooms(conferenceId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/${conferenceId}/rooms`)
        .then((data: any) => {
          if (data.roomList) {
            resolve(data.roomList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateRooms(conferenceId: number, params: { roomList: ConferenceRoomRequestModel[] }) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`${this.url}/${conferenceId}/rooms`, params)
        .then((data: any) => {
          if (data.roomList) {
            resolve(data.roomList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getRoomImages(conferenceId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/${conferenceId}/room/images`)
        .then((data: any) => {
          if (data.roomImageList) {
            resolve(data.roomImageList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateRoomImages(conferenceId: number, params: { imageList: number[] }) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`${this.url}/${conferenceId}/room/images`, params)
        .then((data: any) => {
          if (data.roomImageList) {
            resolve(data.roomImageList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSchedule(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/schedule/${id}`, null)
        .then((data: any) => {
          if (data.schedule) {
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  uploadScheduleExcel(conferenceId: number, formData: FormData) {
    // /schedule/{id}/excel
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`${this.url}/${conferenceId}/schedule/excel`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          resolve(data);
          // if (data.roomImageList) {
          //   resolve(data.roomImageList);
          // } else {
          //   reject({
          //     message: "not found item",
          //   });
          // }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createSchedule(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`${this.url}/schedule`, strParams)
        .then((data: any) => {
          if (data.schedule) {
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateSchedule(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`${this.url}/schedule/${id}`, strParams)
        .then((data: any) => {
          if (data.schedule) {
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  deleteSchedule(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`${this.url}/schedule/${id}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getScheduleList(params: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/schedule`, null, params)
        .then((data: any) => {
          if (data.scheduleList) {
            resolve(data.scheduleList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ConferenceService();
