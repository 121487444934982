
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import UpdateEvent from "@/models";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { UpdateEventType } from "@/types";
import core from "@/core";
import TimePickerModal from "@/modals/core/TimePickerModal.vue";
import { ConferenceRoomModel, ConferenceScheduleModel } from "@/models/conference/conference.model";
import { cloneDeep } from "lodash";
import ConferenceService from "@/services/conference/conference.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    conference_schedule_title: "논문 제목",
    conference_schedule_presenter: "발표자",
    conference_schedule_presentationArea: "발표분야",
    conference_schedule_startDate: "발표분야",
    conference_schedule_room: "Room",
    conference_schedule_author1: "제1저자",
    conference_schedule_author2: "제2저자",
    conference_schedule_author3: "제3저자",
    conference_schedule_author4: "제4저자",
    conference_schedule_author5: "제5저자",
    conference_schedule_author6: "제6저자",
    conference_schedule_author7: "제7저자",
    conference_schedule_author8: "제8저자",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "ScheduleAddEditModal",
  components: { TimePickerModal },
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            type: "add",
            conferenceId: null as number | null,
            minDate: "",
            maxDate: "",
            schedule: null as ConferenceScheduleModel | null,
            roomList: [] as ConferenceRoomModel[],
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    form: {
      title: "",
      presentationArea: "",
      startAt: "",
      endAt: "",
      organization: "",
      author1: "",
      author2: "",
      author3: "",
      author4: "",
      author5: "",
      author6: "",
      author7: "",
      author8: "",
    },
    modal: {
      timepicker: {
        start: "",
        visible: false,
        params: {
          time: "",
          type: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
      room: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    view: {
      room: "",
    },
    datePicker: {
      text: "",
      menu: false,
      date: [core.date.instance().format("YYYY-MM-DD"), core.date.instance().format("YYYY-MM-DD")],
      minDate: core.date.instance().format("YYYY-MM-DD"),
    },
    selectedRoom: null as ConferenceRoomModel | null,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const params = this.properties.params as any;
      if (params.type === "add") {
        const date = core.date.instance(params.minDate).format("YYYY-MM-DD");
        this.datePicker.date = [date, date];
        this.form.startAt = core.date.instance(date).format("YYYY-MM-DDT00:00:00");
        this.form.endAt = core.date.instance(date).format("YYYY-MM-DDT00:00:00");
        this.changedAllDay();
        this.changedDate();
      } else if (params.type === "edit") {
        const schedule = params.schedule;
        this.form.startAt = schedule.startAt;
        this.form.endAt = schedule.endAt;
        this.datePicker.date = [
          core.date.instance(schedule.startAt).format("YYYY-MM-DD"),
          core.date.instance(schedule.endAt).format("YYYY-MM-DD"),
        ];
        this.form.title = schedule.title;
        // this.form.presenter = schedule.presenter;
        this.form.presentationArea = schedule.presentationArea;
        this.form.organization = schedule.organization;
        for (let i = 1; i <= 8; i++) {
          const value = schedule["author" + i];
          if (value != null) {
            this.form["author" + i] = value;
          } else {
            this.form["author" + i] = "";
          }
        }
        if (schedule.conferenceRoomId != null) {
          params.roomList.some((room) => {
            if (schedule.conferenceRoomId === room.id) {
              this.selectedRoom = room;
              this.view.room = room.name;
            }
          });
        }
        console.log("schedule : ", schedule);
      } else {
        console.log("invalid type : ", params.type);
      }
    });
  },
  watch: {
    "datePicker.date"() {
      this.changedDate();
    },
    "modal.timepicker.updateEvent"(val) {
      const event = this.getComponentUpdateEvent(this.modal.timepicker);
      if (event != null && event.result == UpdateEventType.CONFIRM) {
        const item = event.item;
        if (item.type === "startAt") {
          const prev = this.form.startAt;
          const startAt =
            core.date.instance(this.form.startAt).format("YYYY-MM-DD") + "T" + item.time + ":00";
          const calendar = core.date.calendar(startAt, this.form.endAt, prev);
          this.form.startAt = calendar.startAt;
          this.form.endAt = calendar.endAt;
        } else if (item.type === "endAt") {
          const prev = this.form.endAt;
          const endAt =
            core.date.instance(this.form.endAt).format("YYYY-MM-DD") + "T" + item.time + ":00";
          const calendar = core.date.calendar(this.form.startAt, endAt, null, prev);
          this.form.startAt = calendar.startAt;
          this.form.endAt = calendar.endAt;
        } else {
          console.log("Unknown item, ", item);
        }
      }
    },
    "view.room"() {
      if (this.isBlank(this.view.room)) {
        this.selectedRoom = null;
      }
    },
  },
  methods: {
    showScheduleDateModal() {
      const datePicker = this.datePicker;
      datePicker.menu = true;
    },
    showScheduleTimeModal(type: string, at: string) {
      const moment = core.date.instance(at);
      const modal = this.modal.timepicker;
      modal.params.time = moment.format("HH:mm");
      modal.params.type = type;
      modal.start = "";
      if (type === "startAt") {
        modal.start = "08:00";
      } else if (type === "endAt") {
        const startAtMoment = core.date.instance(this.form.startAt);
        const endAtMoment = core.date.instance(this.form.endAt);
        const strStartAt = startAtMoment.format("YYYYMMDD");
        const strEndAt = endAtMoment.format("YYYYMMDD");
        //console.log(`strStartAt : ${strStartAt}, strEndAt : ${strEndAt}`);
        if (strStartAt === strEndAt) {
          modal.start = core.date.instance(this.form.startAt).format("HH:mm");
        }
      }
      modal.visible = true;
    },
    getDate(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("YYYY년MM월DD일(ddd)");
    },
    getTime(at) {
      if (core.utils.validate.isBlank(at)) {
        return "";
      }
      return core.date.instance(at).format("a hh:mm");
    },
    changedAllDay() {
      const sec = core.date
        .instance(this.form.endAt)
        .diff(core.date.instance(this.form.startAt), "seconds");
      if (sec == 0) {
        const startHour = core.date.instance().add(1, "hours").format("HH");
        const endHour = core.date.instance().add(2, "hours").format("HH");
        this.form.startAt = core.date
          .instance(this.form.startAt)
          .format("YYYY-MM-DDT" + startHour + ":00:00");
        this.form.endAt = core.date
          .instance(this.form.endAt)
          .format("YYYY-MM-DDT" + endHour + ":00:00");
      }
      console.log(`change allday, startAt ${this.form.startAt}, endAt ${this.form.endAt}`);
    },
    changedDate() {
      const dateList = this.datePicker.date;
      // console.log("change date : ", dateList);
      if (dateList != null && dateList.length > 1) {
        let preStartMoment = core.date.instance(this.form.startAt);
        let preEndMoment = core.date.instance(this.form.endAt);
        let startMoment = core.date.instance(dateList[0] + "T" + preStartMoment.format("HH:mm:ss"));
        let endMoment = core.date.instance(dateList[1] + "T" + preEndMoment.format("HH:mm:ss"));
        if (startMoment.isAfter(endMoment)) {
          const tempMoment = startMoment;
          startMoment = endMoment;
          endMoment = tempMoment;
          this.datePicker.date = [dateList[1], dateList[0]];
          return;
        }
        this.datePicker.text = `${startMoment.format("YYYY-MM-DD")} ~ ${endMoment.format(
          "YYYY-MM-DD"
        )}`;
        this.form.startAt = startMoment.format("YYYY-MM-DDTHH:mm:ss");
        this.form.endAt = endMoment.format("YYYY-MM-DDTHH:mm:ss");
      }
    },
    async deleteItem() {
      try {
        const type = this.properties.params.type;
        if (type === "edit") {
          const id = this.properties.params.schedule.id;
          await ConferenceService.deleteSchedule(id);

          this.close(UpdateEventType.DELETE, id);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async createConferenceRoom(name) {
      const conferenceId = this.properties.params.conferenceId;
      const roomList = this.properties.params.roomList;
      const paramRoomList = cloneDeep(roomList);
      paramRoomList.push({ name: name });
      console.log("create conferenceRoom : ", name);
      core.loader.show();
      const list = (await ConferenceService.updateRooms(conferenceId, {
        roomList: paramRoomList,
      })) as ConferenceRoomModel[];
      const newItem = list[list.length - 1];
      roomList.push(newItem);
      core.loader.hide();
      return newItem;
    },
    async submit() {
      if (await this.validate()) {
        core.loader.show();
        const type = this.properties.params.type;
        const form = cloneDeep(this.form) as any;
        form.conferenceId = this.properties.params.conferenceId;
        if (this.selectedRoom != null) {
          form.conferenceRoomId = this.selectedRoom.id;
        }
        console.log("form : ", form);

        let id = null as number | null;
        if (type === "edit") {
          id = this.properties.params.schedule.id;
        }

        let schedule =
          this.properties.params.type === "add"
            ? await ConferenceService.createSchedule(form)
            : await ConferenceService.updateSchedule(id!, form);

        console.log("schedule : ", schedule);
        core.loader.hide();
        this.close(UpdateEventType.CONFIRM, schedule);
      }
    },
    confirm() {
      this.submit();
    },
  },
});
