
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { ConferenceModel } from "@/models/conference/conference.model";
import core from "@/core";

export default mixins(MixinsCommon).extend({
  name: "ConferenceListItemComponent",

  props: {
    item: {
      type: Object as () => ConferenceModel,
      default: () => {
        return null as ConferenceModel | null;
      },
    },
  },
  methods: {},
});
