
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import { LayoutType, RouteMeta } from "@/router";
import { cloneDeep } from "lodash";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import HomeComponent from "@/components/home/HomeComponent.vue";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "Home",
  components: { HomeComponent },
  data: () => ({
    bodyMaxHeight: 0,
    extensionHeight: 65,
    app: store.state.app,
    prop: null as any,
  }),
  created() {
    this.windowResize();
  },
  mounted() {
    this.$nextTick(() => {
      const meta = this.$route.meta as RouteMeta;
      const layoutList = cloneDeep(meta.layoutList) as LayoutType[];
      layoutList.push(LayoutType.APP_BAR);
      this.prop = {
        layoutList: layoutList,
        appBarMenu: meta.appBarMenu,
      };

      this.windowResize();
    });
  },
  watch: {
    "app.size"() {
      this.windowResize();
    },
    "app.webSocketConnected"() {
      this.windowResize();
    },
  },
  methods: {
    windowResize() {
      this.bodyMaxHeight = this.app.size.height;
    },

    toggleMenu() {
      if (this.app.showNav == null) this.app.showNav = true;
      this.app.showNav = !this.app.showNav;
    },
  },
});
