
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import {
  ConferenceRoomImageModel,
  ConferenceRoomOptionModel,
  ConferenceScheduleModel,
} from "@/models/conference/conference.model";
import core from "@/core";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import PublicConferenceService from "@/services/conference/public-conference.service";
import PublicUserScheduleService from "@/services/conference/public-user-schedule.service";
import { ConferenceUserScheduleModel } from "@/models/conference/conference-user-schedule.model";
import UpdateEvent from "@/models";
import SelectScheduleAlarmModal from "@/modals/conference/SelectScheduleAlarmModal.vue";
import CoreOpenlayersImageMap from "@/core/core-openlayers-image-map";
import ConferenceService from "@/services/conference/conference.service";
import { Feature } from "ol";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "ScheduleView",
  components: { SelectScheduleAlarmModal },
  data: () => ({
    scheduleId: 0,
    schedule: null as ConferenceScheduleModel | null,
    userSchedule: null as ConferenceUserScheduleModel | null,
    modal: {
      selectScheduleAlarm: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    openLayersMap: null as CoreOpenlayersImageMap | null,
    marker: null as Feature | null,
  }),
  mounted() {
    this.$nextTick(async () => {
      const id = Number(this.$route.params.id);
      if (isNaN(id) || id <= 0) {
        await this.notFound();
        return;
      }
      this.scheduleId = id;
      const schedule = await this.getSchedule();
      if (schedule != null && schedule.conferenceRoom != null && schedule.conferenceRoom.option) {
        const option = schedule.conferenceRoom.option;
        PublicConferenceService.getRoomImage(schedule.conferenceId, option.fileDetailId).then(
          (response) => {
            const roomImage = response as ConferenceRoomImageModel;

            this.initMap({
              uri: roomImage.image.uri,
              width: roomImage.image.width,
              height: roomImage.image.height,
              x: option.x,
              y: option.y,
            });
          }
        );
      }
      this.checkUserSchedule();
    });
  },
  watch: {
    "app.data.userSchedule.ready"() {
      this.checkUserSchedule();
    },
    async "modal.selectScheduleAlarm.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectScheduleAlarm);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const alarmMin = event.item;
          // console.log("alarmMin : ", alarmMin);
          const orgUserSchedule = this.userSchedule as ConferenceUserScheduleModel;
          const params = {
            uuid: this.auth.uuid,
            scheduleId: orgUserSchedule.scheduleId,
            alarmTimeMin: alarmMin,
          };
          const userSchedule = await PublicUserScheduleService.update(params);

          ["alarmTimeMin", "alarmAt"].forEach((key) => {
            if (userSchedule[key] == null) {
              userSchedule[key] = null;
            }
          });
          core.utils.copy(userSchedule, orgUserSchedule);
        }
      }
    },
  },
  methods: {
    toHtml(text) {
      return core.utils.format.textToHtml(text);
    },
    showSelectScheduleAlarmModal() {
      const modal = this.modal.selectScheduleAlarm;
      modal.visible = true;
    },
    checkUserSchedule() {
      if (this.isNotBlank(this.auth.uuid)) {
        let exists = false;

        const userScheduleData = this.app.data.userSchedule;
        // console.log("userScheduleData : ", userScheduleData);
        if (userScheduleData.ready) {
          userScheduleData.list.some((userSchedule) => {
            // console.log(
            //   `user schedule id : ${userSchedule.scheduleId}, scheduleId : ${this.scheduleId}`
            // );
            if (userSchedule.scheduleId === this.scheduleId) {
              this.userSchedule = userSchedule;
              exists = true;
              return true;
            }
          });
        }
        // console.log("exists : ", exists);
        this.appBarChangeMenuVisible("add", !exists);
        this.appBarChangeMenuVisible("remove", exists);

        if (!exists) {
          this.userSchedule = null;
        }
      } else {
        this.appBarChangeMenuVisible("add", false);
        this.appBarChangeMenuVisible("remove", false);
        this.userSchedule = null;
      }
    },
    async appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "add") {
        // console.log("add schedule");
        core.loader.show();
        try {
          const params = {
            uuid: this.auth.uuid,
            scheduleId: this.scheduleId,
          };
          const userSchedule = await PublicUserScheduleService.create(params);

          ["alarmTimeMin", "alarmAt"].forEach((key) => {
            if (userSchedule[key] == null) {
              userSchedule[key] = null;
            }
          });
          // console.log("userSchedule : ", userSchedule);

          let exists = false;
          this.app.data.userSchedule.list.some((preUserSchedule, index) => {
            if (preUserSchedule.scheduleId === this.scheduleId) {
              exists = true;
              core.utils.copy(userSchedule, preUserSchedule);
              return true;
            }
          });
          if (!exists) {
            this.app.data.userSchedule.list.push(userSchedule);
          }
          this.checkUserSchedule();
        } catch (e) {
          console.log(e);
        } finally {
          core.loader.hide();
        }
      } else if (menu.id === "remove") {
        core.loader.show();
        try {
          const response = await PublicUserScheduleService.delete(this.scheduleId, {
            uuid: this.auth.uuid,
          });
          this.app.data.userSchedule.list.some((userSchedule, index) => {
            if (userSchedule.scheduleId === this.scheduleId) {
              this.app.data.userSchedule.list.splice(index, 1);
              return true;
            }
          });
          // console.log("remove schedule. ", response);
          this.checkUserSchedule();
        } catch (e) {
          console.log(e);
        } finally {
          core.loader.hide();
        }
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    dateToText() {
      if (this.schedule != null) {
        const schedule = this.schedule as ConferenceScheduleModel;
        const startMoment = core.date.instance(schedule.startAt);
        const endMoment = core.date.instance(schedule.endAt);
        let strDate = "";
        // console.log(startMoment.format("YYYY-MM-DD"));
        // console.log(endMoment.format("YYYY-MM-DD"));
        if (startMoment.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
          strDate =
            startMoment.format("YYYY년 M월 D일(ddd) a h:mm") + " ~ " + endMoment.format("a h:mm");
        } else {
          strDate =
            startMoment.format("YYYY년 M월 D일(ddd) a h:mm") +
            " ~ " +
            endMoment.format("YYYY년 M월 D일(ddd) a h:mm");
        }
        return strDate;
      }
      return "";
    },
    async getSchedule() {
      try {
        const schedule = (this.schedule = (await PublicConferenceService.getSchedule(
          this.scheduleId
        )) as ConferenceScheduleModel);
        // console.log("schedule : ", schedule);
        // const room = ConferenceService.getR;
        return schedule;
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
      return null;
    },
    initMap(item: any) {
      let width = item.width;
      if (this.app.size.width < width) {
        width = this.app.size.width;
      }
      const openLayersMap = (this.openLayersMap = new CoreOpenlayersImageMap({
        map: {
          element: this.$refs.map,
          image: {
            url: item.uri,
            size: {
              width: item.width,
              height: item.height,
            },
          },
          zoom: 0,
          minZoom: 0,
          maxZoom: 10,
          enable: {
            rotate: false,
            zoom: true,
          },
        },
        callback: {
          click: null,
        },
      }));

      if (item.x && item.y) {
        this.marker = openLayersMap.addMarker({
          name: "position",
          position: {
            x: item.x,
            y: item.y,
          },
        });
      }
    },
  },
});
