
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import UserListItemComponent from "@/components/user/ListItemComponent.vue";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import core from "@/core";
import ConferenceService from "@/services/conference/conference.service";
import Constant from "@/store/constant";
import draggable from "vuedraggable";
import { cloneDeep } from "lodash";
import {
  ConferenceModel,
  ConferenceRoomImageModel,
  ConferenceRoomModel,
  ConferenceRoomRequestModel,
  ImageModel,
} from "@/models/conference/conference.model";
import ConferenceRoomAddEditModal from "@/modals/conference/ConferenceRoomAddEditModal.vue";
import UpdateEvent from "@/models";

export default mixins(MixinsPageForm, MixinsAppBarMenu).extend({
  name: "MgmtConferenceAddEdit",
  components: {
    ConferenceRoomAddEditModal,
    UserListItemComponent,
    draggable,
  },

  data: () => ({
    processing: false,
    datePicker: {
      text: "",
      menu: false,
      date: [core.date.instance().format("YYYY-MM-DD"), core.date.instance().format("YYYY-MM-DD")],
      minDate: core.date.instance().format("YYYY-MM-DD"),
    },
    form: {
      name: "",
      address1: "",
      address2: "",
      latitude: null as number | null,
      longitude: null as number | null,
    },
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
    imageList: [] as ImageModel[],
    roomIndex: 0,
    roomList: [] as ConferenceRoomRequestModel[],
    type: "add",
    conference: null as ConferenceModel | null,
    modal: {
      conferenceRoomAddEdit: {
        visible: false,
        params: {
          type: "add",
          item: null as ConferenceRoomRequestModel | null,
          imageList: [] as ImageModel[],
        },
        updateEvent: null as null | UpdateEvent,
      },
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const type = (this.type = String(this.$route.params.type));
      if (type === "edit") {
        const id = Number(this.$route.query.id);
        if (isNaN(id) || id <= 0) {
          await this.notFound();
          return;
        }
        this.appBarChangeMenuVisible("delete", true);

        core.loader.show();
        const conference = await ConferenceService.get(id);
        // console.log("conference : ", conference);
        this.conference = conference;
        for (const key of Object.keys(this.form)) {
          const value = conference[key];
          if (value != null) {
            this.form[key] = String(value);
          }
        }
        this.datePicker.date = [conference.startDate, conference.endDate];
        const roomImages = (await ConferenceService.getRoomImages(
          id
        )) as ConferenceRoomImageModel[];
        // console.log("roomImages : ", roomImages);
        roomImages.forEach((roomImage) => {
          this.imageList.push(roomImage.image);
        });

        const roomList = (await ConferenceService.getRooms(id)) as ConferenceRoomModel[];
        roomList.forEach((room) => {
          this.roomList.push({
            index: this.roomIndex++,
            id: room.id,
            name: room.name,
            option: room.option,
          });
        });
      }
    });
  },
  watch: {
    "datePicker.date"(dateList) {
      this.changedDate();
    },
    "modal.conferenceRoomAddEdit.updateEvent"() {
      const modal = this.modal.conferenceRoomAddEdit;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          if (modal.params.type === "add") {
            const item = event.item as any;
            // console.log("item : ", item);
            this.roomList.push({
              index: this.roomIndex++,
              name: item.name,
              option: item.option,
            });
          } else {
            const item = event.item as any;
            const params = modal.params;
            if (params.item != null) {
              const orgRoom = params.item as ConferenceRoomRequestModel;
              orgRoom.name = item.name;
              orgRoom.option = item.option;
            }
          }
          // this.updateRooms();
        } else if (event.result === UpdateEventType.DELETE) {
          const params = modal.params;
          if (params.item != null) {
            const orgRoom = params.item as ConferenceRoomRequestModel;
            this.roomList.some((item, index) => {
              if (orgRoom.index === item.index) {
                this.roomList.splice(index, 1);
                return true;
              }
            });
          }
        } else {
          console.log("invalid result : ", event);
        }
      }
    },
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.UPDATE) {
        const location = event.item as any;
        this.form.address1 = location.address1;
        this.form.address2 = location.address2;
        this.form.latitude = location.latitude;
        this.form.longitude = location.longitude;
        console.log("location : ", location);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },
  methods: {
    async appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "delete") {
        if (this.type === "edit") {
          const confirmResult = await core.alert.show({
            title: "확인",
            body: "정말 삭제하시겠습니까?",
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "삭제",
          });
          if (confirmResult === "confirm") {
            const conference = this.conference as ConferenceModel;

            try {
              await ConferenceService.delete(conference.id);
              this.goBack(UpdateEventType.DELETE, conference);
            } catch (e) {
              console.log(e);
            }
          }
        }
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    showAddRoom() {
      // console.log("show add edit room");
      const modal = this.modal.conferenceRoomAddEdit;
      modal.params.type = "add";
      modal.params.item = null;
      modal.params.imageList = this.imageList;
      modal.visible = true;
    },

    showEditRoom(item: ConferenceRoomRequestModel) {
      const modal = this.modal.conferenceRoomAddEdit;
      modal.params.type = "edit";
      modal.params.item = item;
      modal.params.imageList = this.imageList;
      modal.visible = true;
    },
    removeRoom(index: number) {
      const list = this.roomList;
      list.splice(index, 1);
      //this.updateRooms();
    },
    async updateRooms() {
      if (this.type === "edit") {
        core.loader.show();
        const conference = this.conference as ConferenceModel;
        const list = (await ConferenceService.updateRooms(conference.id, {
          roomList: this.roomList,
        })) as ConferenceRoomModel[];
        this.roomList = [];
        this.roomIndex = 0;
        list.forEach((room) => {
          this.roomList.push({
            index: this.roomIndex++,
            id: room.id,
            name: room.name,
            option: room.option,
          });
        });
        // console.log("update roomList : ", this.roomList);
        core.loader.hide();
      }
    },
    async updateRoomImages() {
      if (this.type === "edit") {
        core.loader.show();
        const conference = this.conference as ConferenceModel;

        const params = {
          imageList: [] as number[],
        };
        this.imageList.forEach((image) => {
          params.imageList.push(image.id);
        });
        const roomImageList = (await ConferenceService.updateRoomImages(
          conference.id,
          params
        )) as ConferenceRoomImageModel[];
        // console.log("roomImageList : ", roomImageList);
        this.imageList = [];
        roomImageList.forEach((roomImage) => {
          this.imageList.push(roomImage.image);
        });
        core.loader.hide();
      }
    },
    changedDate() {
      const dateList = this.datePicker.date;
      // console.log("change date : ", dateList);
      if (dateList != null && dateList.length > 1) {
        let startMoment = core.date.instance(dateList[0]);
        let endMoment = core.date.instance(dateList[1]);
        if (startMoment.isAfter(endMoment)) {
          const tempMoment = startMoment;
          startMoment = endMoment;
          endMoment = tempMoment;
          this.datePicker.date = [dateList[1], dateList[0]];
          return;
        }
        this.datePicker.text = `${startMoment.format("YYYY-MM-DD")} ~ ${endMoment.format(
          "YYYY-MM-DD"
        )}`;
      }
    },
    showSearchAddressPage() {
      // console.log("showSearchAddressPage");
      let params = null as any;
      if (this.form.address1 != null && this.form.address1.length > 0) {
        params = {
          address1: this.form.address1,
          address2: this.form.address2,
          latitude: this.form.latitude,
          longitude: this.form.longitude,
          level: 2,
        };
      }

      this.$router.push({ path: "/search-address", query: params });
    },
    showImageSelectView() {
      (this.$refs.imageFile as any).click();
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    async deleteImage(item) {
      // console.log("delete image item : ", item);

      try {
        core.loader.show();
        //console.log("result : ", result);
        const length = this.imageList.length;
        for (let i = 0; i < length; i++) {
          const image = this.imageList[i];
          if (image.id === item.id) {
            this.imageList.splice(i, 1);
            break;
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
        // this.updateRoomImages();
      }
    },
    getFilename(uri: string) {
      if (uri != null) {
        const pos = uri.lastIndexOf("/");
        if (pos > -1) {
          return uri.substring(pos + 1, uri.length);
        }
      }
      return uri;
    },
    async onChangeImageFile() {
      //console.log("onChangeImageFile");
      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        for (let i = 0; i < length; i++) {
          try {
            const file = files[i];
            const imageFile = await core.utils.image.getImageBlob(file);
            // console.log("new image : ", imageFile);
            const formData = new FormData();
            formData.append("imageFile", imageFile.blob, imageFile.filename);
            core.loader.show(`이미지 업로드중... (${i + 1}/${length})`);
            const fileDetailId = Number(await ConferenceService.uploadImage(formData));
            // console.log("fileDetailId : ", fileDetailId);
            this.imageList.push({
              id: fileDetailId,
              thumbnailUri: imageFile.dataUri,
              uri: imageFile.dataUri,
              width: imageFile.width,
              height: imageFile.height,
            });
            // const srcImage = (await SpecialUnitPriceService.addSrcImage(
            //   placeId,
            //   fileDetailId
            // )) as SpecialUnitPriceSrcImageModel;
            // console.log("unitPriceImage : ", srcImage);
          } catch (e: any) {
            core.http.sendErrorLog(e);
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
            return;
          }
        }
        core.loader.hide();

        (this.$refs.imageFile as any).value = "";

        // this.updateRoomImages();
      }
    },
    async submit() {
      if (await this.validate()) {
        core.loader.show();

        // console.log("validate");
        const params = cloneDeep(this.form) as any;
        params.startDate = core.date.instance(this.datePicker.date[0]).format("YYYY-MM-DD");
        params.endDate = core.date.instance(this.datePicker.date[1]).format("YYYY-MM-DD");

        let conference: ConferenceModel | null = null;

        if (this.type === "add") {
          conference = (await ConferenceService.create(params)) as ConferenceModel;
          console.log("create conference : ", conference);
        } else if (this.conference != null && this.type === "edit") {
          conference = (await ConferenceService.update(
            this.conference.id,
            params
          )) as ConferenceModel;
          console.log("update conference : ", conference);
        } else {
          console.log("invalid param");
          return;
        }
        await this.updateRoomImages();
        await this.updateRooms();
        core.loader.hide();
        this.goBack(UpdateEventType.RELOAD);
      }
    },
  },
});
