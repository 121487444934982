// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./registerServiceWorker";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePageStack from "@/plugins/vue-page-stack";

import { extend, localize } from "vee-validate";
import { email, max, min, required } from "vee-validate/dist/rules";
import core from "@/core";
import TopToolbar from "@/components/core/TopToolbar.vue";
import SelectSearchModal from "@/modals/core/SelectSearchModal.vue";
import SelectCameraModal from "@/modals/core/SelectCameraModal.vue";

import Constant from "@/store/constant";
import ComponentTemplate from "@/components/core/ComponentTemplate.vue";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import AppBar from "@/components/core/AppBar.vue";

// require("@/assets/js/kakao.js");
// (window as any).Kakao.init(Constant.kakao.javascriptKey);

Vue.config.productionTip = false;

const options = {
  timeout: false,
  maxToasts: 1,
};

Vue.use(Toast, options);

Vue.use(Viewer);

// 공통 유틸 초기화
core.init(store, router, Vue.$toast);

Vue.component("app-bar", AppBar);
Vue.component("top-toolbar", TopToolbar);

// 아이템 검색 및 선택 모달
Vue.component("select-search-modal", SelectSearchModal);

// 카메라 선택 모달
Vue.component("select-camera-modal", SelectCameraModal);

Vue.component("scc-component-template", ComponentTemplate);

if (!("toJSON" in Error.prototype))
  Object.defineProperty(Error.prototype, "toJSON", {
    value: function () {
      const alt = {};

      Object.getOwnPropertyNames(this).forEach(function (key) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alt[key] = this[key];
        } catch (e) {
          alt[key] = e;
        }
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });

Vue.config.errorHandler = (error, vm, info) => {
  console.log("error : ", error);
  core.http.sendErrorLog(error);
};

if (process.env.NODE_ENV === "production") {
  (window as any).console = {};
  (window as any).console.log = function () {};
  (window as any).console.warn = function () {};
  (window as any).console.error = function () {};
}
Vue.prototype.$eventBus = new Vue();

// page stack(이전 페이지 저장 처리)
Vue.use(VuePageStack, {
  router,
  routerBeforeTask: store.state.app.routerBeforeTask,
  name: "vue-page-stack",
  keyName: "stackKey",
});

// validate
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("phone", {
  validate(value) {
    return core.utils.validate.phone(value);
  },
  message: "휴대전화 형식이 올바르지 않습니다",
});

extend("password", {
  params: ["target"],
  validate(value, params) {
    const target = (params as any).target;
    // console.log('value : ', value, ', target : ', target);
    return value === target;
  },
  message: "비밀번호 확인이 일치하지 않습니다",
});

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    user_username: "아이디",
    user_password: "비밀번호",
    user_confirmPassword: "비밀번호 확인 ",
    user_email: "이메일",
    user_name: "이름",
    user_phone: "휴대전화",
    user_emailCertificationNumber: "인증번호",
    user_phoneCertificationNumber: "인증번호",
    user_currentPassword: "현재 비밀번호",
    user_role: "사용자 역할",
    profile_koName: "한글 이름",
    profile_enName: "영문 이름",
    // profile_department: "소속/직책",
    profile_organization: "소속",
    profile_position: "직책",
    profile_education: "학력",
    profile_phone: "휴대전화",
    profile_officeNumber: "사무실번호",
    profile_email: "이메일",
    profile_address: "주소",
    profile_jg_category: "직군",
    memo: "메모",
    conference_name: "학술대회",
    conference_date: "날짜",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
