
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { ConferencePresentationAreaModel } from "@/models/conference/conference.model";
import { localize } from "vee-validate";
import Constant from "@/store/constant";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    conference_filter_keyword: "키워드",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "ConferenceFilterModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            // presentationAreaFilterList: [] as string[],
            // presentationAreaList: [] as ConferencePresentationAreaModel[],
            itemList: [] as string[],
            inputText: null as string | null,
            selectedItemList: [] as string[],
          },
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    form: {
      text: "",
    },
    selected: [] as string[],
    itemList: [] as string[],
  }),
  mounted() {
    this.$nextTick(async () => {
      const inputText = this.properties.params.inputText;
      if (inputText != null) {
        this.form.text = inputText;
      }

      const filterList = this.properties.params.selectedItemList;
      const filterMap = {} as any;
      if (filterList != null) {
        filterList.forEach((filter) => {
          filterMap[String(filter)] = true;
        });
      }
      // console.log("filterMap : ", filterMap);

      const itemList = this.properties.params.itemList;
      itemList.forEach((item) => {
        this.itemList.push(item);
        if (filterMap[item] != null) {
          this.selected.push(item);
        }
      });
      // console.log("selected : ", this.selected);
    });
  },
  methods: {
    select(item) {
      // console.log("item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
    async confirm() {
      if (await this.validate()) {
        // console.log("selected : ", this.selected);
        this.close(UpdateEventType.CONFIRM, {
          selected: this.selected,
          text: this.form.text,
        });
      }
    },
  },
});
