
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export enum AlarmType {
  MIN_10 = "10",
  MIN_15 = "15",
  MIN_30 = "30",
  MIN_45 = "45",
  MIN_60 = "60",
}

export default mixins(MixinsModal).extend({
  name: "SelectScheduleAlarmModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    alarmTypeList: [
      {
        id: AlarmType.MIN_10,
        text: "10분전",
      },
      {
        id: AlarmType.MIN_15,
        text: "15분전",
      },
      {
        id: AlarmType.MIN_30,
        text: "30분전",
      },
      {
        id: AlarmType.MIN_45,
        text: "45분전",
      },
      {
        id: AlarmType.MIN_60,
        text: "60분전",
      },
      {
        id: null,
        text: "알림 없음",
      },
    ],
  }),
  methods: {
    select(item) {
      // console.log("item : ", item);
      this.close(UpdateEventType.CONFIRM, item);
    },
  },
});
