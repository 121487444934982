
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import UserService from "@/services/user/user.service";

export default mixins(MixinsPageForm).extend({
  name: "FindUsername",
  data: () => ({
    processing: false,
    form: {
      email: "",
    },
    emailAuth: {
      visible: {
        help: false,
      },
      waitTimeMillis: 600000,
      remainingTimeMin: "10",
      date: null as Date | null,
      interval: null as number | null,
      expired: false,
      processing: false,
    },
    formConvertField: {
      email: "user_email",
    },
    find: {
      result: false,
      username: "",
    },
  }),
  beforeRouteLeave(to, from, next) {
    [this.emailAuth].forEach((auth) => {
      if (auth.interval != null) {
        clearInterval(auth.interval);
        auth.interval = null;
      }
    });
    next();
  },
  mounted() {
    this.$nextTick(async () => {
      const token = this.$route.query.t;
      if (this.isNotBlank(token)) {
        this.replaceState();

        // token 검증
        try {
          this.processing = true;
          const result = (await UserService.getFindUsername(token as string)) as any;
          this.form.email = result.email;
          this.find.result = true;
          this.find.username = result.username;
          console.log("result : ", result);
        } catch (e) {
          console.log(e);
        } finally {
          this.processing = false;
        }
      }
    });
  },
  watch: {
    "form.email"() {
      this.emailAuth.visible.help = false;
    },
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        const auth = this.emailAuth;
        auth.processing = true;

        try {
          const res = (await UserService.sendEmailFindUsername(this.form.email)) as string;
          //console.log("token : ", token);
          auth.visible.help = true;
          auth.date = new Date();
          auth.expired = false;
          auth.remainingTimeMin = "10분";
          if (auth.interval == null) {
            auth.interval = setInterval(() => {
              const date = new Date();
              if (auth.date != null) {
                const elapsedTimeMillis = date.getTime() - auth.date.getTime();
                const remainingTimeMillis = auth.waitTimeMillis - elapsedTimeMillis;
                if (remainingTimeMillis > 0) {
                  auth.remainingTimeMin =
                    String(parseInt(String(remainingTimeMillis / 60000)) + 1) + "분";
                  //console.log("remainingTimeMillis : ", remainingTimeMillis);
                } else {
                  auth.date = null;
                  auth.remainingTimeMin = "만료";
                  auth.expired = true;
                }
              } else if (auth.interval != null) {
                clearInterval(auth.interval);
                auth.interval = null;
              }
            }, 200);

            // 인증번호 필드로 포커스 이동
            this.moveFocus("emailCertificationNumber");
            this.resetValidate();
          }
        } catch (e) {
          console.log(e);
          this.errorSubmit(e, this.formConvertField);
        } finally {
          auth.processing = false;
        }
      }
    },
  },
});
