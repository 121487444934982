
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import ConferenceService from "@/services/conference/conference.service";
import ConferenceListItemComponent from "@/components/conference/ListItemComponent.vue";

export default mixins(MixinsPageForm, MixinsAppBarMenu, MixinsTable, MixinsScroll).extend({
  name: "MgmtConference",
  components: { ConferenceListItemComponent },

  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
  }),
  mounted() {
    // 테이블 초기화
    this.initTable({
      service: ConferenceService,
      serviceFunctionKey: "getTable",
      itemHeight: 100,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
  },
  activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (event.result === UpdateEventType.UPDATE) {
        if (event.path.indexOf("search") > -1) {
          this.updateTableItem("id", event.item);
        } else {
          this.addTableItem("id", event.item);
        }
      } else if (event.result === UpdateEventType.DELETE) {
        this.deleteTableItem("id", event.item);
      } else {
        console.log("unknown event : ", event);
      }
    }
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      if (menu.id === "search") {
        this.$router.push({
          path: "/mgmt/conference/search",
        });
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
  },
});
