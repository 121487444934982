import korea from "vee-validate/dist/locale/ko.json";
import core from "@/core";

class Constant {
  appName = "한국농업기계학회 학술대회";
  validate = {
    language: {
      korea: korea,
    },
  };
  url = "https://conference.ksam.or.kr";
  param = {
    login: [],
  };
  kakao = {
    javascriptKey: "bdbfef8ff97510a8e15c9fdccc40d275",
    restApiKey: "872c200b35d45540682a3409df383e4b",
  };
  viewerOptions = {
    inline: false,
    button: true,
    navbar: true,
    title: false,
    toolbar: {
      zoomIn: true,
      zoomOut: true,
      oneToOne: true,
      reset: true,
      rotateLeft: true,
      rotateRight: true,
      flipHorizontal: true,
      flipVertical: true,
      prev: true,
      play: true,
      next: true,
      download: async (e) => {
        const viewer = document.querySelector(
          ".viewer-container.viewer-in .viewer-canvas img"
        ) as any;
        if (viewer) {
          try {
            await core.http.downloadFile(viewer.src);
          } catch (e) {
            console.log(e);
          }
        }
      },
    },
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: true,
    fullscreen: true,
    keyboard: true,
  };
}

export default new Constant();
