import AbstractTableService from "@/services/core/abstract-table.service";
import { ConferenceModel, ConferenceRoomRequestModel } from "@/models/conference/conference.model";
import core from "@/core";

class PublicConferenceService extends AbstractTableService<ConferenceModel> {
  constructor() {
    super("/api/v1/p/conference", "conference");
  }

  getActiveList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}`)
        .then((data: any) => {
          if (data.conferenceList) {
            resolve(data.conferenceList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getPresentationAreaList(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/${id}/presentation-area`)
        .then((data: any) => {
          if (data.presentationAreaList) {
            resolve(data.presentationAreaList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getRooms(conferenceId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/${conferenceId}/rooms`)
        .then((data: any) => {
          if (data.roomList) {
            resolve(data.roomList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getRoomImage(conferenceId: number, fileDetailId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/${conferenceId}/room/image/${fileDetailId}`)
        .then((data: any) => {
          if (data.roomImage) {
            resolve(data.roomImage);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getRoomImages(conferenceId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/${conferenceId}/room/images`)
        .then((data: any) => {
          if (data.roomImageList) {
            resolve(data.roomImageList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSchedule(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/schedule/${id}`, null)
        .then((data: any) => {
          if (data.schedule) {
            resolve(data.schedule);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getScheduleList(params: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`${this.url}/schedule`, null, params)
        .then((data: any) => {
          if (data.scheduleList) {
            resolve(data.scheduleList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new PublicConferenceService();
