export default class CoreColor {
  hslToRgb(hue, sat, light) {
    let t1, t2, r, g, b;
    hue = hue / 60;
    if (light <= 0.5) {
      t2 = light * (sat + 1);
    } else {
      t2 = light + sat - light * sat;
    }
    t1 = light * 2 - t2;
    r = this.hueToRgb(t1, t2, hue + 2) * 255;
    g = this.hueToRgb(t1, t2, hue) * 255;
    b = this.hueToRgb(t1, t2, hue - 2) * 255;
    return {
      r: Number(r.toFixed(0)),
      g: Number(g.toFixed(0)),
      b: Number(b.toFixed(0)),
    };
  }

  hueToRgb(t1, t2, hue) {
    if (hue < 0) hue += 6;
    if (hue >= 6) hue -= 6;
    if (hue < 1) return (t2 - t1) * hue + t1;
    else if (hue < 3) return t2;
    else if (hue < 4) return (t2 - t1) * (4 - hue) + t1;
    else return t1;
  }

  toHex(n) {
    let hex = n.toString(16);
    while (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  }

  hslToHex(hue, sat, light) {
    const rgb = this.hslToRgb(hue, sat, light);
    const r = this.toHex(rgb.r);
    const g = this.toHex(rgb.g);
    const b = this.toHex(rgb.b);
    return "#" + r + g + b;
  }

  hexToRgb(hex): { r: number; g: number; b: number } {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      //return "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)";
      return {
        r: Number(((c >> 16) & 255).toFixed(0)),
        g: Number(((c >> 8) & 255).toFixed(0)),
        b: Number((c & 255).toFixed(0)),
      };
    }
    throw new Error("Bad Hex");
  }
}
