
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import UpdateEvent from "@/models";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import ConferenceService from "@/services/conference/conference.service";
import core from "@/core";
import { UpdateEventType } from "@/types";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    excel_file: "엑셀 파일",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "ConferenceUploadExcelModal",
  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          visible: false,
          params: {
            conferenceId: 0,
          },
          // 업데이트 이벤트 처리용 변수
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
  },
  data: () => ({
    file: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      console.log("mounted");
    });
  },
  methods: {
    downloadExcel() {
      // console.log("양식 다운로드");
      const filename = encodeURIComponent("학술대회양식.xlsx");
      const url = `/dn/excel-form/${filename}`;
      core.http.downloadFile(url);
    },
    async submit() {
      if (await this.validate()) {
        console.log("upload excel");
        console.log("file : ", this.file);
        if (this.file != null) {
          core.loader.show();
          const file = this.file;
          const formData = new FormData();
          formData.append("excelFile", file);

          const result = await ConferenceService.uploadScheduleExcel(
            this.properties.params.conferenceId,
            formData
          );
          // console.log("result : ", result);
          core.loader.hide();
          // const params = {
          //   name: this.form.name,
          // };
          this.close(UpdateEventType.CONFIRM, result);
        }
      }
    },
    confirm() {
      this.submit();
    },
  },
});
