import core from "@/core";
import AbstractTableService from "@/services/core/abstract-table.service";
import { UserModel } from "@/models/user/user.model";

class UserMgmtService extends AbstractTableService<UserModel> {
  constructor() {
    super("/api/v1/user", "user");
  }

  updateRole(id: number, role): Promise<UserModel> {
    const strParam = JSON.stringify({
      role: role,
    });
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/v1/user/${id}/role`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          if (data[this.itemKey]) {
            resolve(data[this.itemKey]);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new UserMgmtService();
