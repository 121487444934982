import qs from "qs";
import core from "@/core";
import { Role, UserModel } from "@/models/user/user.model";

class UserService {
  register(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/register`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(params) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`/api/v1/user/me`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  login(params) {
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/v1/login`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/logout`)
        .then((data) => {
          // console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          // console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getMyInfo(): Promise<UserModel> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/user/me`, { ignoreAlertModal: true })
        .then((data: any) => {
          //console.log('result: ', data);
          if (data.user != null) {
            resolve(data.user as UserModel);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateMobileDeviceInfo(params: {
    crudType: string;
    deviceToken: string;
    timezone: string | null;
    locale: string | null;
    manufacturer: string | null;
    model: string | null;
    product: string | null;
    hardware: string | null;
    os: string | null;
    versionSdk: number | null;
    versionRelease: string | null;
    packageName: string | null;
    versionCode: number | null;
    versionName: string | null;
  }) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/p/mobile-device`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createEmailVerify(email: string) {
    const strParam = JSON.stringify({
      email: email,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/email-verify`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data.token);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  checkEmailVerify(token: string, email: string, certificationNumber: string) {
    const strParam = JSON.stringify({
      token: token,
      email: email,
      certificationNumber: certificationNumber,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/email-verify/check`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getFindUsername(token: string) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/user/find-username`, null, {
          token: token,
        })
        .then((data) => {
          //console.log('result: ', data);
          if (data.user) {
            resolve(data.user);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  sendEmailFindUsername(email: string) {
    const strParam = JSON.stringify({
      email: email,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/find-username`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getFindPassword(token: string) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/user/find-password`, null, {
          token: token,
        })
        .then((data) => {
          //console.log('result: ', data);
          if (data.user) {
            resolve(data.user);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  sendEmailFindPassword(email: string) {
    const strParam = JSON.stringify({
      email: email,
    });
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/find-password`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updatePassword(currentPassword: string, newPassword: string, newConfirmPassword: string) {
    const strParam = JSON.stringify({
      currentPassword: currentPassword,
      newPassword: newPassword,
      newConfirmPassword: newConfirmPassword,
    });
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`/api/v1/user/me/password`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  createProfileImage(formData: FormData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/v1/user/me/profile/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  withdraw() {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`/api/v1/user/me/withdraw`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  roleToText(role: string) {
    if (role === Role.ADMIN) {
      return "관리자";
    } else if (role === Role.MANAGER) {
      return "매니저";
    } else if (role === Role.USER) {
      return "사용자";
    } else if (role === Role.WAIT_USER) {
      return "가입대기";
    } else {
      return "알 수 없음";
    }
  }

  rolesToText(roles: Role[]) {
    let textRole = "";
    if (roles != null) {
      roles.forEach((role) => {
        if (role === Role.ADMIN) {
          textRole += this.roleToText(role) + ",";
        } else if (role === Role.MANAGER) {
          textRole += this.roleToText(role) + ",";
        } else if (role === Role.USER) {
          textRole += this.roleToText(role) + ",";
        } else if (role === Role.WAIT_USER) {
          textRole += this.roleToText(role) + ",";
        } else {
          textRole += this.roleToText(role) + ",";
        }
      });
      if (textRole.length > 0) {
        textRole = textRole.substring(0, textRole.length - 1);
      }
    }
    return textRole;
  }
}

export default new UserService();
